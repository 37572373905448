export default {
  ADD_ITEM(state, item) {
    state.ServiceTypes.unshift(item);
  },
  SET_ServiceType(state,  ServiceTypes) {
    state.ServiceTypes =  ServiceTypes;
  },
  UPDATE_ServiceType(state,  ServiceTypes) {
    const  ServiceTypesIndex = state.ServiceTypes.findIndex(p => p.Id ==  ServiceTypes.Id);
    if (ServiceTypesIndex != -1) {
      Object.assign(state. ServiceTypes[ServiceTypesIndex],  ServiceTypes);
    }
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.ServiceTypes.findIndex(p => p.Id == itemId);
    state.ServiceTypes.splice(ItemIndex, 1);
  }
};

