import axios from "@/axios.js";

export default {
  addItem({ commit }, item) {
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      debugger;
      axios
        .post("API/ServiceType/Add", item,{headers:Token})
        .then(response => {
          debugger;
          resolve(response);
          commit(
            "ADD_ITEM",
            Object.assign(item, { ID: response.data.data.ID })
          );
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchDataListItems({ commit }) {
    debugger;
    
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios
        .get("API/ServiceType/Index",{headers:Token})
        .then(response => {
          resolve(response);
          commit("SET_ServiceType", response.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  updateItem(context, item) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios
        .put("API/ServiceType/Update", item,{headers:Token})
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  GetItemByID(context, itemid) {
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      debugger;
      axios
      .get("API/ServiceType/GetTypeById?Id=" + itemid,{headers:Token})
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  removeItem({ commit }, item) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios
        .delete("API/ServiceType/Delete?Id=" + item,{headers:Token})
        .then(response => {
          resolve(response);
          commit("REMOVE_ITEM", item);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  UploadImage(context, image) {
    //var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    const formData = new FormData();
    formData.append("file",image, image.name);
    return new Promise((resolve, reject) => {
      axios
        .post("/API/Common/UploadFile", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.status == 200) {
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};
