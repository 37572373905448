<template>
  <div>
    <div id="data-list-list-view" class="data-list-container">


      <data-view-sidebar
      :isSidebarActive="addNewDataSidebar"
      @closeSidebar="toggleDataSidebar"
      :data="sidebarData"
    />


      <vs-table
        ref="table"
        id="Scroll"
        pagination
        :max-items="itemsPerPage"
        search
        :data="ServiceTypeOptions"
      >


        <div
          slot="header"
          class="flex flex-wrap-reverse items-center flex-grow justify-between"
        >

        <div class="vx-row">
            <vs-button
            v-if="checkPermission(اضافه) || CheckUser()"
            style="font-size: 16px;font-family:almarai ;color: green;"
              type="border"

              icon-pack="feather"
              icon="icon-plus"
              @click="addNewData"
              >{{ $t("AddNew") }}</vs-button
            >
          </div>

        

          <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
            <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2"
                >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
                {{
                  ServiceTypeOptions.length - currentPage * itemsPerPage > 0
                    ? currentPage * itemsPerPage
                    : ServiceTypeOptions.length
                }}
                of {{ ServiceTypeOptions.length }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="itemsPerPage = 4">
                <span>4</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 10">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 15">
                <span>15</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 20">
                <span>20</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <template slot="thead">
          <vs-th style="font-size: 16px;font-family:almarai ; color: #85a961;" sort-key="NameEn">{{ $t("NameEn") }}</vs-th>
          <vs-th style="font-size: 16px;font-family:almarai ; color: #85a961;" sort-key="NameAr">{{ $t("NameAr") }}</vs-th>
          <vs-th style="font-size: 16px;font-family:almarai ; color:crimson;" sort-key="Action">{{ $t("Action") }}</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <tr
              :data="tr"
              :key="indextr"
              v-for="(tr, indextr) in data"
              @click="editData(tr)"
            >
              <vs-td>
                <p class="product-name font-medium truncate" style="font-family: almarai;">
                  <span v-if="tr.nameAr">{{ tr.nameEn }}</span>
                  <span v-else>N/A</span>
                </p>
              </vs-td>
              <vs-td>
                <p class="product-name font-medium truncate" style="font-family: almarai;">
                  <span v-if="tr.nameAr">{{ tr.nameAr }}</span>
                  <span v-else>N/A</span>
                </p>
              </vs-td>

              <vs-td class="whitespace-no-wrap">
                  <feather-icon
                  v-if="checkPermission(تعديل) || CheckUser()"
                    icon="EditIcon"
                    @click.stop="editData(tr)"
                    svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  />
                <feather-icon
                v-if="checkPermission(حذف) || CheckUser()"
                  icon="TrashIcon"
                  svgClasses="w-10 h-5 hover:text-primary stroke-current"
                  @click.stop="openConfirm(tr)"
                />
              </vs-td>
            </tr>
          </tbody>
        </template>
      </vs-table>
    </div>
  </div>
</template>

<script>
import { domain } from "@/gloabelConstant.js";
import moduleServiceType from "@/store/ServiceType/moduleServiceType.js";
import DataViewSidebar from "./DataViewSidebar.vue";

export default {
  components: {
    DataViewSidebar,
  },

  data() {
    return {
      itemsPerPage: 10,
      //ServiceTypeOptions: [],
      baseURL: domain,
      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},
      rowDataForDelete: {},
      activeConfirm: false,
    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },

    ServiceTypeOptions() {
        debugger;
        return this.$store.state.ServiceTypeList.ServiceTypes;
      }
  },

  methods: {
    addNewData() {
      this.sidebarData = {};
      this.toggleDataSidebar(true);
    },
CheckUser() {
      debugger;
          if (typeof localStorage !== 'undefined') {
            const userInfo = localStorage.getItem("userInfo");
            if (userInfo) {
              return JSON.parse(userInfo).UserType === "SuperAdmin";
            }
          }
          return false; // Default return if localStorage or userInfo is not available
        },
      checkPermission(OPName) {
    const screenRolesPermissions = JSON.parse(localStorage.getItem('ScreenRolesPermissions')) || [];
    
    // Ensure Page and Page.Name are not null or undefined
    return screenRolesPermissions.some(permission => 
      permission.Operation && permission.Operation.Name === OPName
    );
  },
    editData(data) {
      console.log("editData" + data.id);
      debugger;
      this.sidebarData = data;
      this.toggleDataSidebar(true);
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val;
    },
    toggleSearchSidebar(val) {
      this.showSearchSidebar = val;
    },

    openConfirm(data) {
      debugger;
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: this.$t("DeleteConfirmText"),
        accept: this.acceptAlert,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
      });
    },
    acceptAlert() {
      this.deleteData(this.rowDataForDelete);
      this.$vs.notify({
        color: "success",
        title: "Deleted record",
        text: this.$t("DeletedSuccessfully"),
      });
    },

    // deleteData(data) {
    //   debugger;
    //   this.$store
    //     .dispatch("ServiceTypeList/DeleteObject", data)

    //     .catch((err) => {
    //       console.error(err);
    //     });
    // },

    deleteData(data) {
  debugger;
  this.$store.dispatch("ServiceTypeList/DeleteObject", data)
    .then(() => {
      this.$store.dispatch("ServiceTypeList/fetchDataListItems");
    })
    .catch((err) => {
      console.error(err);
      this.$store.dispatch("ServiceTypeList/fetchDataListItems");
    });
},
//GetData(){
  //this.$store.dispatch("ServiceTypeList/fetchDataListItems")
  //.then((res) => {
    //  debugger;
      //this.ServiceTypeOptions = res.data.data;
   // });
//},
  },
  created() {
    if (!moduleServiceType.isRegistered) {
      this.$store.registerModule("ServiceTypeList", moduleServiceType);
      moduleServiceType.isRegistered = true;
    }
    this.$store.dispatch("ServiceTypeList/fetchDataListItems");

  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<style lang="scss">
#data-list-list-view small {
  color: rgb(255, 115, 0);
  text-decoration: underline rgb(255, 115, 0);
}

.vxCard:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0%;
  margin-left: 10%;
  z-index: 500000;
  width: 25%;
  height: 20%;
  border-top: 3px solid rgb(255, 115, 0) !important;
  border-bottom: 0 none rgba(255, 0, 0, 0.9) !important;
  border-right: 0 none rgba(255, 0, 0, 0.9) !important;
  border-left: 0 none rgba(0, 0, 0, 0.9) !important;
}
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
