
import state from "./moduleServiceTypeState.js"
import mutations from "./moduleServiceTypeMutations.js"
import getters from "./moduleServiceTypeGetters.js"
import actions from './moduleServiceTypeActions.js';
export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
