<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar position-right parent="body" default-index="1" color="primary"
    class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">

    <div class="mt-6 flex items-center justify-between px-6">
      <h4 style="color: white;">{{ Object.entries(this.data).length === 0 ? $t('AddNew') : $t('Update') }} {{ $t('Item') }}</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>

    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings">
      <vs-card title="icon">
        <h4 style="color: white;">{{ $t("NameAr") }}</h4>
        <vs-input class="w-full" v-model="nameAr" v-validate="'required'" name="nameAr" />
        <h4 style="color: white;">{{ $t("NameEn") }}</h4>
        <vs-input class="w-full" v-model="nameEn" v-validate="'required'" name="nameEn" />


      </vs-card>


<!--
      <vs-card title="icon">
            <h4>{{ $t("ImageUrl") }}</h4>
            <template v-if="ServiceTypeModel.IconURLPath">
              <div
                class="img-container w-32 h-32 mx-auto flex items-center justify-center"
              >
                <img
                  :src="baseURL + ServiceTypeModel.IconURLPath"
                  class="w-full h-full object-contain"
                />
              </div>
            </template>

            <div class="modify-img flex justify-between mt-5">
              <input
                type="file"
                class="hidden"
                ref="updateImgInput"
                @change="updateCurrImg"
                accept="image/*"
              />
              <vs-button
                class="mr-4"
                type="flat"
                @click="$refs.updateImgInput.click()"
              >
                Update Image
              </vs-button>
              <vs-button
                type="flat"
                color="#999"
                @click="ServiceTypeModel.IconURLPath = null"
              >
                Remove Image
              </vs-button>
            </div>
          </vs-card>
-->
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp;
            <vs-button
         
              type="border"

              icon-pack="feather"
              icon="icon-plus"
              @click="submitData"
              style="background-color: aliceblue; color: black; font-weight: bold;" 
              color="info"
              >{{ $t('Save') }}</vs-button
            >
            &nbsp; &nbsp; &nbsp;

      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">{{ $t('Cancel') }}</vs-button>
    </div>

  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { domain } from "@/gloabelConstant.js";
import moduleServiceType from "@/store/ServiceType/moduleServiceType.js";

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      //Countries:[],
      default: () => { }
    }
  },
  data() {
    return {
      baseURL: domain,
      ServiceTypeOptions: [],
      Id: null,
      ShowOffersWithNoRestrict: false,
      nameAr: '',
      nameEn: '',
      ServiceTypeModel: {
        IconURLPath: "",
      },
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    };
  },

  watch: {
    isSidebarActive(val) {
      debugger;
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        let { id, nameAr, nameEn } = JSON.parse(
          JSON.stringify(this.data)
        );
        this.Id = id;
        this.nameAr = nameAr;
        this.nameEn = nameEn;


      }

      console.log("Id isSidebarActive:" + this.Id );

    }
  },


  computed: {
    isSidebarActiveLocal: {

      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
        }
      }
    },

    isFormValid() {
      return !this.errors.any() && this.Id;
    }
  },

  methods: {

    //GetData() {
      //this.$store.dispatch("ServiceTypeList/fetchDataListItems").then((res) => {
       // debugger;
       // this.ServiceTypeOptions = res.data.data;
     // });
    //},
    initValues() {
      if (this.data.id) return;
      this.Id = null;
      this.ShowOffersWithNoRestrict = false;
      this.nameAr = '';
      this.nameEn = '';
    },

     submitData() {
      debugger;
      try {
        debugger;
        const result =  this.$validator.validateAll();
        if (result) {
          const obj = {
            Id: this.Id,
            nameAr: this.nameAr,
            nameEn: this.nameEn,
            IconURLPath:"Icon Test",
          };
          console.log("Id f:" + obj.Id);

          debugger;
          if (this.Id != null && this.Id >= 0) {
            debugger;
             this.$store.dispatch("ServiceTypeList/updateItem", obj);
          } else {
            console.log("Id:" +obj.Id);
            delete obj.Id;
            obj.popularity = 0;
             this.$store.dispatch("ServiceTypeList/addItem", obj);
          }
          this.$store.dispatch("ServiceTypeList/fetchDataListItems");
           this.$store.dispatch("ServiceTypeList/fetchDataListItems");
          window.showSuccess();
          this.$emit("closeSidebar");
          this.initValues();
        }
      } catch (error) {
        console.error(error);
      }
    },



  },

  components: {
    VuePerfectScrollbar
  },
  create() {
    if (!moduleServiceType.isRegistered) {
      this.$store.registerModule("ServiceTypeList", moduleServiceType);
      moduleServiceType.isRegistered = true;
    }

    this.$store.dispatch("ServiceTypeList/fetchDataListItems");
  }
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
</style>